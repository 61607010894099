'use strict';

class SignupController {
  //start-non-standard
  user = {};
  errors = {};
  submitted = false;
  privacity = false;
  //end-non-standard

  constructor(Auth, $state) {
    this.Auth = Auth;
    this.$state = $state;
  }

  register(form) {
    this.submitted = true;
    this.privacity = true;

    if (form.$valid) {
      var user = {
        name: this.user.name,
        email: this.user.email,
        password: this.user.password
      }

      if (!!this.$state.params.dst) {
        user.dst = this.$state.params.dst
      }

      this.Auth.createUser(user)
      .then(() => {
        // Account created, redirect to home
        this.$state.go('main');
      })
      .catch(err => {
              
          form.email.$setValidity('mongoose', false);
          this.errors.email = err.data.token;
       
      });
    }
  }
}

angular.module('mvpcarrefourApp')
  .controller('SignupController', SignupController);
